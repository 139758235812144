import BaseModel from '@/libs/base/dataModel/BaseModel';
import DeviceOperateModel from '@/pages/050__deviceManager/chargingPileList/model/DeviceOperateModel';

export class OperateTemplateListModel extends BaseModel {
  constructor () {
    super();
    // 模板id
    this.templateId = '';
    // 模板名称
    this.name = '';
    // 模板类型
    this.operationConfigType = '';
    this.operationConfigTypeDesc = '';
    // 模板创建时间
    this.createTime = '';
    // 模板创建人(姓名+手机号)
    this.createUser = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }
}

export class OperateTemplateDetailModel extends OperateTemplateListModel {
  constructor () {
    super();
    // 模板名称
    this.name = '';
    // 运营参数模板类别
    this.category = 1;
    this.categoryDesc = '';

    this.h5DeviceOperateModel = null;
  }

  initData (resData) {
    super.initData(resData);
    const deviceModel = new DeviceOperateModel();
    this.h5DeviceOperateModel = deviceModel.initDataWithCategory(resData.category, resData.portPowerConfig || resData.swipeCardConfig || resData.operationConfig);
    return this;
  }

  static createAdditionModel () {
    const model = new this();
    model.h5DeviceOperateModel = new DeviceOperateModel();
    return model;
  }
}
